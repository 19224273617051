import ComingSoonPage from './pages/ComingSoon';

function App() {
  return (
    <div style={{textAlign: "center"}}>
        <ComingSoonPage />
    </div>
  );
}

export default App;
