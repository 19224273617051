export const COLORS = {
    BackgroundColor: '#1D252F',
    SecondBack: '#232C39',
    ThirdBlack: '#3B485D',
    MainColor: '#29940A',
    MainColorOff: '#C1EAB5',
    White: '#E1E1E1',
    NotifColor: '#F7CB15',
    Negatif: '#F25C52',
    SelectColor: '#007DCD',
    Grey: '#D9D9D9',
    Sport: '#FF2AB7',
    Reading: '#527F26',
    Health: '#472475',
    VideoGame: '#007DCD'
}